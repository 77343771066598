@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
    --brand: #E43D3D;
    --brand-hover: #d43a3a;

    --gray: gray;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}


button:not(.Toastify__close-button) {
    background: var(--brand);
    border: none;
    color: white;
    border-radius: 99px;
    padding: 9px 30px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.25s ease;

    &:hover {
        background: var(--brand-hover);
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(1px);
        transition: all 0.05s ease;
    }

    &:disabled {
        background: var(--gray);
        pointer-events: none;
        user-select: none;
    }
}

input {
    background: none;
    border: 1px solid var(--gray);
    font-size: 1rem;
    border-radius: 99px;
    padding: 4px 30px;

    &:focus {
        border-color: var(--brand);
        outline: none;
    }

    &:not(:placeholder-shown) {
        border-color: var(--brand);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.TooltipContent {
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 15px;
    line-height: 1;
    color: white;
    background-color: var(--brand);
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    user-select: none;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
  }
  .TooltipContent[data-state='delayed-open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }
  .TooltipContent[data-state='delayed-open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  .TooltipContent[data-state='delayed-open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  .TooltipContent[data-state='delayed-open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }
  
  .TooltipArrow {
    fill: var(--brand);
  }

@keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .watermark {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: 4rem;
}

.toastContainer {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .toast {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    animation-name: animateToastIn;
    overflow: hidden;

    padding: 20px 40px;
    background: var(--brand);
    transition: all 0.25s ease;
    font-size: 1.4rem;
    max-width: 30rem;
    border-radius: 10px;
  }
}

@keyframes animateToastIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes animateToastOut {
  from {
    height: max-content;
    transform: translateY(0);
  }
  to {
    height: 0;
    transform: translateY(5px)
  }
}