.Game {
    height: 100vh;
    width: 100vw;
    display: grid;
    place-items: center;

    .state {
        isolation: isolate;
        position: relative;

        .player {
            background: var(--player-color);
            color: white;
            padding: 20px 40px;
            border-radius: 10px;
            margin-bottom: 10px;

            h1 {
                font-size: 3rem;
            }
        }

        .rule {
            width: 100%;
            position: relative;
            display: flex;
            margin-bottom: 10px;

            p {
                background: var(--player-color);
                color: white;
                padding: 20px;
                border-radius: 10px;
                word-wrap: normal;
                width: 35rem;
                font-size: 1.2rem;
            }
        }

        .cardImg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            opacity: .3;
        }

        button {
            width: 100%;
        }
    }

    .players {
        position: fixed;
        left: 20px; 
        font-size: 1.2rem;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .player {
            display: flex;
            align-items: center;
            padding: 2px 10px;
            color: black;
            position: relative;
            isolation: isolate;
            transition: color 0.25s ease;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 5px;
                height: 100%;
                background: var(--color);
                z-index: -1;
                border-radius: 5px;
                transition: width 0.25s ease;
            }

            &.active {
                color: white;
                &::after {
                    width: 100%;
                }
            }
        }
    }

    .status {
        position: fixed;
        right: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 5px;
        
        .stat {
            background: var(--brand);
            padding: 2px 10px;
            border-radius: 5px;
            color: white;
            width: max-content;
            font-size: 1.2rem;
            display: flex;

            img {
                width: 25px;
                margin-right: 10px;
            }

            &.id {
                font-family: "Roboto Mono", sans-serif;
                letter-spacing: .3rem;
            }

            &.king {
                background: var(--king-color);

                span.name {
                    margin-right: 10px;
                }

                span.timeLeft {
                    opacity: .7;
                }
            }
        }
    }

    .controls {
        position: fixed;
        bottom: 20px;
        display: flex;
        gap: 20px;

        .control {
            display: grid;
            place-items: center;
            padding: 10px;
            background: #E8E8E8;
            border-radius: 5px;
            cursor: pointer;
            transition: transform 0.25s ease;
            user-select: none;

            &:hover {
                background: #d8d8d8;
                transform: translateY(-2px);
            }

            &:active {
                transform: translateY(1px);
                transition: transform 0.05s ease;
            }

            &.info {
                display: flex;
                align-items: center;
                gap: 10px;
                
                &.isKing {
                    background: rgb(221, 221, 150);
                }
            }
        }
    }

    .playerCards {
        display: flex;
        justify-content: center;
        position: fixed;
        overflow: hidden;
        bottom: 80px;
        width: 100%;
        left: 0;

        .card {
            margin-right: -60px;
            transform: translate(-40px, 30px);
            transition: all 0.25s ease;
            cursor: pointer;

            img {
                height: 10rem;
            }

            &.hover {
                &:hover {
                    margin-right: 10px;
                    margin-left: 70px;
                }
            }

            &.selected {
                transform: translate(-40px, 0px);
                margin-right: 10px;
                margin-left: 70px;
            }
        }
    }

    .trade-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        isolation: isolate;
        z-index: 9999;
        display: grid;
        place-items: center;
    
        .modal-bg {
            position: absolute;
            background: black;
            opacity: 0.4;
            width: 100vw;
            height: 100vh;
            z-index: -1;
        }

        .modal-content {
            background: white;
            padding: 20px;
            border-radius: 10px;

            h2 {
                margin-bottom: 20px;
            }
            
            .players-list {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 10px;
            }
        }
    }
}