.Host {

    .Lobby {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: white;
        display: flex;
        justify-content: center;
        padding: 2rem;
        z-index: 99;

        
        .wrapper {
            display: flex;
            justify-content: space-between;
            height: 100%;
            width: 100%;
            max-width: 130rem;
        }

        .col {
            width: 100%;
            color: white;
            padding: 1rem;

            &.info {
                .id {
                    display: flex;
                    gap: 10px;
                    margin-bottom: 10px;

                    .game-id {
                        width: 100%;
                        background: var(--brand);
                        border-radius: 10px;
                        padding: 20px;

                        .pin-number {
                            letter-spacing: 1rem;
                            font-family: "Roboto Mono", sans-serif;
                        }
                    }

                    .qr {
                        height: 105px;
                        aspect-ratio: 1 / 1;
                        background: var(--brand);
                        border-radius: 10px;
                    }
                }
            }

            &.presence {
                color: black;

                .players {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    gap: 5px;
                    .player {
                        color: white;
                        background: var(--player-color);
                        padding: 10px 20px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}