.Landing {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            margin-bottom: 20px;
        }
        
        .controls {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            button {
                width: 100%;
            }

            .seperator {
                display: flex;
                width: 100%;
                align-items: center;
                gap: 20px;
                color: gray;

                &::after,&::before {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background: gray;
                }
            }

            .join {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                input {
                    &[type="number"] {
                        font-family: "Roboto Mono", sans-serif;
                        &::placeholder {
                            font-family: "Inter", sans-serif;
                        }
                    }

                    width: 100%;
                }
            }
        }
    }
}
